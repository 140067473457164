import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsCard, MtsCardBody } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../store';

import Typography from '../../../components_/Typography';

import { ITrackPoint } from '../../../models/types';
import moment from 'moment/moment';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    margin: `${spacing(1)}px 0`,
    cursor: 'pointer'
  },
  body: {
    '& > *:not(:last-child)': {
      marginRight: spacing(2)
    }
  },
  count: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  info: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  }
}));

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setTrack: d.reportTracks.setTrack
  };
};

type connectedProps = ReturnType<typeof mapDispatch>;

type IProps = {
  track: ITrackPoint;
  key: number;
} & connectedProps;

const Item: FC<IProps> = ({ track, setTrack }) => {
  const { user, points } = track;

  const history = useHistory();
  const classes = useStyles();

  const handleClick = async () => {
    // console.log(track);
    setTrack(track);
    // localStorage.removeItem('mapId');
    // await resetMap();
    // await setOffice(office);
    //
    // history.push(paths.map.office.root);
  };

  return (
    <MtsCard onClick={handleClick} className={classes.root}>
      <MtsCardBody>
        <Grid container className={classes.body}>
          <Grid item className={classes.info}>
            <Typography variant="p1Medium" color="text-headline">
              {`${user.lastname} ${user.name[0]}. ${
                user.secondname ? user.secondname[0] + '.' : ''
              }`}
            </Typography>
          </Grid>
          <Grid container className={classes.body}>
            <Grid container className={classes.info}>
              <Typography variant="p3Regular" color="text-headline">
                {`Старт: ${moment(points[0].time).format('L HLTS')}`}
              </Typography>
            </Grid>
            <Grid container className={classes.info}>
              <Typography variant="p3Regular" color="text-headline">
                {`Финиш: ${moment(points[points.length - 1].time).format('L HLTS')}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MtsCardBody>
    </MtsCard>
  );
};

const withConnect = connect(
  null,
  mapDispatch
);

export default withConnect(Item);
