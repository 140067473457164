/**
 * Сервис для работы с камерами
 */

import axios from 'axios';

import { pageSize } from '../constants/pagination';
import { IId } from '../models/types';

export interface IGetAllParams {
  plans: IId;
  name: string;
  pageIndex: number;
}

export interface IUpdateOrCreateBody {
  id: IId | null;
  name: string;
  focalLength: number | null;
  rotationAngle: number | null;
  description: string;
  isNotificationEnabled: boolean;
  coordinateX: number | null;
  coordinateY: number | null;
  coordinateZ: number | null;
  lat: number | null;
  lon: number | null;
  alt: number | null;
  planId: IId;
}

// export interface IGetTypeAllParams {
//   hideSpecial?: boolean;
// }

const url = '/v0/cameras';

const CamerasService = {
  get: (id: IId) => axios.get(`${url}/${id}`),
  getAll: (params: IGetAllParams) => axios.get(`${url}`, { params: { pageSize, ...params } }),
  updateOrCreate: (body: IUpdateOrCreateBody) => axios.post(`${url}`, body),
  remove: (id: IId) => axios.delete(`${url}/${id}`)
  // getTypeAll: (params: IGetTypeAllParams = {}) => axios.get(`${url}/hard_types`, { params }),
  // getSimpleTypeAll: () => axios.get(`${url}/simple_types`)
};

export default CamerasService;
