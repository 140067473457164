import React, { FC, ReactNode } from 'react';
import moment from 'moment';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IUpdateOrCreateBody } from '../../../../services/UsersService';
import { required, phone, email } from '../../../../utils/validation';
import { getTimeFromMinutes, getMinutesFromTime } from '../../../../utils/convertTime';
import { hoursStep, minutesStep } from '../../../../constants/timeSteps';
import useRequestHandler from '../../../../hooks/useRequestHandler';

import ContentForm from '../../../../components_/Content/ContentForm';
import FormBase from '../../../../components_/Form';
import FormGroup from '../../../../components_/Form/FormGroup';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import FormDoubleField from '../../../../components_/Form/FormDoubleField';
import FormInput from '../../../../components_/Form/FormInput';
import FormDropdown from '../../../../components_/Form/FormDropdown';
import FormColorPicker from '../../../../components_/Form/FormColorPicker';
import FormButton from '../../../../components_/Form/FormButton';

import { IProps as IFormProps } from './';
import FormController from './FormController';
import FormDatePicker from '../../../../components_/Form/FormDatePicker';

const useStyles = makeStyles(({ spacing }) => ({
  color: {
    marginTop: spacing(2)
  },
  buttons: {
    '& > *:not(:last-child)': {
      marginRight: spacing(4)
    }
  }
}));

type IUser = IUpdateOrCreateBody;
type IValues = IUser & {
  immobilizedHours: number;
  immobilizedMinutes: number;
  breakMinutes: number;
  breakHours: number;
};

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  data: IFormProps['data'];
  item: IUser | null;
  isCreate: boolean;
  isEdit: boolean;
  onSubmitAction: any;
  onCancel: any;
}

const defaultValues: IValues = {
  lastname: '',
  name: '',
  secondname: '',
  birthday: '',
  username: '',
  phone: '',
  email: '',
  title: '',
  brigadeId: '',
  tabularNumber: '',
  protectionsList: [],
  role: '',
  mapColor: '',
  immobilizedTimeMinutes: 0,
  immobilizedHours: 0,
  immobilizedMinutes: 0,
  workshiftDurationHours: 0,
  workshiftBreakMinutes: 0,
  breakMinutes: 0,
  breakHours: 0
};

const Form: FC<IProps> = ({ data, item, isCreate, isEdit, onSubmitAction, onCancel }) => {
  const { brigades, protections, roles } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const createImmobilizedTime = (minutes: number) => {
    const [immobilizedHours, immobilizedMinutes] = getTimeFromMinutes(minutes);

    return { immobilizedHours, immobilizedMinutes };
  };

  const createBreakTime = (minutes: number) => {
    const [breakHours, breakMinutes] = getTimeFromMinutes(minutes);

    return { breakHours, breakMinutes };
  };

  const initialValues: IValues = {
    ...defaultValues,
    ...(isEdit &&
      item && {
        ...item,
        birthday: item.birthday && moment(item.birthday).format('YYYY-MM-DD'),
        ...(item.protectionsList && {
          // tslint:disable-next-line: no-shadowed-variable
          protectionsList: item.protectionsList.map(item => String(item))
        }),
        ...createImmobilizedTime(item.immobilizedTimeMinutes),
        ...createBreakTime(item.workshiftBreakMinutes)
      })
  };

  const brigadeOptions = brigades.map(({ id, name }) => ({ id, name }));
  const protectionOptions = protections.map(({ id, name }) => ({ id: String(id), name }));
  const roleOptions = roles.map(({ name, friendlyName }) => ({ id: name, name: friendlyName }));

  const submitButtonTitle = isEdit ? 'Сохранить' : 'Добавить';

  const onSubmit = async (values: IValues) => {
    const body = {
      ...values,
      birthday: values.birthday
        ? moment(values.birthday)
            .startOf('day')
            .format()
        : null,
      brigadeId: values.brigadeId ? values.brigadeId : null,
      immobilizedTimeMinutes:
        getMinutesFromTime(values.immobilizedHours, values.immobilizedMinutes) || null,
      workshiftBreakMinutes: getMinutesFromTime(values.breakHours, values.breakMinutes) || null,
      workshiftDurationHours: values.workshiftDurationHours || null
    };

    const error = await onSubmitAction(body);

    requestHandler({
      error,
      entity: 'user',
      onSuccess: onCancel,
      isCreate,
      isEdit
    });
  };

  const render: IRender = ({ handleSubmit }) => {
    return (
      <FormBase style={{ height: '100%' }} onSubmit={handleSubmit}>
        <>
          <ContentForm>
            <Grid>
              <FormGroup title="Персональная информация" size="m">
                <FormRow>
                  <FormCol>
                    <FormInput
                      name="lastname"
                      label="Фамилия"
                      placeholder="Введите фамилию"
                      useFieldConfig={{ validate: required }}
                    />
                  </FormCol>
                  <FormCol>
                    <FormInput
                      name="name"
                      label="Имя"
                      placeholder="Введите имя"
                      useFieldConfig={{ validate: required }}
                    />
                  </FormCol>
                  <FormCol>
                    <FormInput
                      name="secondname"
                      label="Отчество (необязательно)"
                      placeholder="Введите отчество"
                    />
                  </FormCol>
                  <FormCol>
                    <FormDatePicker
                      name="birthday"
                      label="Дата рождения (необязательно)"
                      maxDate={new Date()}
                    />
                    {/*<FormBaseInput*/}
                    {/*  name="birthday"*/}
                    {/*  label="Дата рождения (необязательно)"*/}
                    {/*  type="date"*/}
                    {/*/>*/}
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <FormInput name="username" label="Логин" placeholder="Введите логин" disabled />
                  </FormCol>
                  <FormCol>
                    <FormInput
                      name="phone"
                      label="Контактный номер телефона"
                      placeholder="+7"
                      useFieldConfig={{ validate: value => required(value) || phone(value) }}
                    />
                  </FormCol>
                  <FormCol>
                    <FormInput
                      name="email"
                      label="Email (необязательно)"
                      placeholder="user@company.ru"
                      tooltip="На этот email будут отправлены данные для авторизации.
                       Укажите email сотрудника или выберите структурное
подразделение, у руководителя которого есть email."
                      useFieldConfig={{ validate: email }}
                    />
                  </FormCol>
                  <FormCol />
                </FormRow>
              </FormGroup>
              <FormGroup title="Рабочая информация" size="m">
                <FormRow>
                  <FormCol>
                    <FormInput
                      name="title"
                      label="Должность (необязательно)"
                      placeholder="Введите должность"
                    />
                  </FormCol>
                  <FormCol>
                    <FormDropdown
                      name="brigadeId"
                      label="Структурное подразделение (необязательно)"
                      placeholder="Выберите подразделение"
                      options={brigadeOptions}
                    />
                  </FormCol>
                  <FormCol>
                    <FormInput
                      name="tabularNumber"
                      label="Табельный номер (необязательно)"
                      placeholder="Введите табельный номер"
                    />
                  </FormCol>
                  <FormCol>
                    <FormDropdown
                      name="protectionsList"
                      label="Обязательная экипировка (необязательно)"
                      placeholder="Выберите экипировку"
                      multiple
                      options={protectionOptions}
                    />
                  </FormCol>
                </FormRow>
              </FormGroup>
              <FormGroup title="Настройки" size="m">
                <FormRow>
                  <FormCol>
                    <FormDropdown
                      name="role"
                      label="Роль"
                      placeholder="Выберите роль"
                      options={roleOptions}
                      useFieldConfig={{ validate: required }}
                    />
                  </FormCol>
                  <FormCol>
                    <FormDoubleField
                      label="Интервал обездвиженности (необязательно)"
                      separator="-"
                      left={
                        <FormDropdown
                          name="immobilizedHours"
                          placeholder="Часы"
                          options={hoursStep}
                        />
                      }
                      right={
                        <FormDropdown
                          name="immobilizedMinutes"
                          placeholder="Минуты"
                          options={minutesStep}
                        />
                      }
                    />
                  </FormCol>
                  <FormCol>
                    <FormDropdown
                      name="workshiftDurationHours"
                      label="Длительность смены (необязательно)"
                      placeholder="Выберите длительность смены"
                      options={hoursStep}
                    />
                  </FormCol>
                  <FormCol>
                    <FormDoubleField
                      label="Длительность перерыва (необязательно)"
                      separator="-"
                      left={
                        <FormDropdown name="breakHours" placeholder="Часы" options={hoursStep} />
                      }
                      right={
                        <FormDropdown
                          name="breakMinutes"
                          placeholder="Минуты"
                          options={minutesStep}
                        />
                      }
                    />
                  </FormCol>
                </FormRow>
                <FormRow className={classes.color}>
                  <FormCol>
                    <FormColorPicker name="mapColor" label="Цвет сотрудника на карте" />
                  </FormCol>
                </FormRow>
              </FormGroup>
            </Grid>
            <Grid container justify="center" className={classes.buttons}>
              <MtsButton color={'negative' as ButtonColors} onClick={onCancel}>
                Отмена
              </MtsButton>
              <FormButton>{submitButtonTitle}</FormButton>
            </Grid>
          </ContentForm>
          <FormController isCreate={isCreate} />
        </>
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
