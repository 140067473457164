import React, { FC } from 'react';

import { guids, paths } from '../Root/paths';

import ContentTabs from '../../components_/Content/ContentTabs';
import Tabbar from '../../components_/Tabbar';

const tabs = [
  {
    name: 'Шаблоны уведомлений',
    url: paths.settings.root,
    uid: guids.settings.root
  },
  {
    name: 'Типы экипировки',
    url: paths.settings.protectionTypes.root,
    uid: guids.settings.protectionTypes.root
  }
];

const Tabs: FC = () => {
  return (
    <ContentTabs>
      <Tabbar tabs={tabs} />
    </ContentTabs>
  );
};

export default Tabs;
