/**
 * Сервис для работы с пользователями
 */

import axios from 'axios';

import { IId } from '../models/types';
import { pageSize } from '../constants/pagination';
import webSsoConstants from '../constants/webSsoConstants';

export interface IUpdateOrCreateBody {
  lastname: string;
  name: string;
  secondname: string;
  birthday: string;
  username: string;
  phone: string;
  email: string;
  title: string;
  brigadeId: IId | null;
  tabularNumber: string;
  protectionsList: IId[];
  role: string;
  mapColor: string;
  immobilizedTimeMinutes: number;
  workshiftDurationHours: number;
  workshiftBreakMinutes: number;
}

export interface IGetAllParams {
  fullTextSearch?: string;
  includePreview?: boolean;
  pageIndex?: number;
  pageSize?: number;
}

export interface IGetBrigadeAllParams {
  fullTextSearch?: string;
  includeOther?: boolean;
  pageIndex?: number;
  pageSize?: number;
}

export interface IBody {
  username: string;
  password: string;
}

export interface IUserBody {
  id: IId;
  lastname: string;
  name: string;
  secondname: string;
  birthday: string;
  username: string;
  brigadeId: number;
  lead: string;
  title: string;
  tabularNumber: string;
  mapColor: string;
  protectionsList?: [];
  descr: string;
  avatar?: File;
}

export interface IBrigadeBody {
  id: IId;
  name: string;
}

export interface IFilterParams {
  fullTextSearch?: string;
  pageSize?: number;
  includePreview?: boolean;
  includeOther?: boolean;
}

export interface IShortUsersParams {
  fts?: string;
}

export interface ILoginParams {
  name?: string;
  lastName?: string;
  secondName?: string;
}

export interface IGetByTokenParams {
  preview?: boolean;
}

export interface ISecurableTree {
  uid?: IId;
  parent: IId;
  type: string;
  systemName: string;
  friendlyName: string;
  hash?: string;
  clientVersion?: string;
}

export interface IUpdateOrCreateBrigadeBody {
  id: IId;
  name: string;
  leadId: IId;
  employeesList: IId[];
}

const url = '/user';

const UsersService = {
  getToken: (code: string) =>
    axios.get(`${url}/sso_callback`, {
      params: { code, redirect_uri: webSsoConstants.redirectUri }
    }),
  getRefreshedToken: (refreshToken: string | null) =>
    axios.post(`${url}/refreshToken?refreshToken=${refreshToken}`),
  get: (userName: string) => axios.get(`${url}/info/${userName}`, { params: { preview: true } }),
  getAll: (params: IGetAllParams) =>
    axios.get(`${url}/infoAll`, { params: { pageSize, ...params } }),
  getAllShort: (params: IShortUsersParams = {}) => axios.get(`${url}/infoAllShort`, { params }),
  getByToken: (params: IGetByTokenParams = {}) => axios.get(`${url}/info`, { params }),
  getRoles: () => axios.get(`${url}/roles`),
  getBrigade: (id: IId) => axios.get(`${url}/brigades/id=${id}`),
  getBrigadeAll: (params: IGetBrigadeAllParams = {}) =>
    axios.get(`${url}/brigades`, { params: { pageSize, ...params } }),
  getLogin: (params: ILoginParams = {}) => axios.get(`${url}/sampleLogin`, { params }),
  updateOrCreate: (body: IUserBody) => axios.post(url, body),
  updateOrCreateAvatar: (formData: FormData) => axios.post(`${url}/photo`, formData),
  updateOrCreateBrigade: (body: IBrigadeBody) => axios.post(`${url}/brigades`, body),
  delete: (id: number) => axios.delete(`${url}/info/userId=${id}`),
  deleteBrigade: (id: IId) => axios.delete(`${url}/brigades?brigadeId=${id}`),
  login: (body: IBody) => axios.post(`${url}/loginFront`, body),
  getSecurableTreeVersion: (root: IId) => axios.get(`${url}/sectree/version`, { params: { root } }),
  getSecurables: (root: IId) => axios.get(`${url}/sectree/right?root=${root}`),
  createSecurableTree: (root: IId, body: ISecurableTree[]) =>
    axios.post(`${url}/sectree?root=${root}`, body)
};

export default UsersService;
