import { Models } from '@rematch/core';

/* PLOP_INJECT_MODEL_IMPORT */
import { reportNotifications } from './reportNotifications';
import { reportPhysicalState } from './reportPhysicalState';
import { reportUseProtection } from './reportUseProtection';
import { reportWorkingTime } from './reportWorkingTime';
import { reportViolations } from './reportViolations';
import { reportStayInAreas } from './reportStayInAreas';
import { reportHeatMap } from './reportHeatMap';
import { auth } from './auth';
import { user } from './user';
import { alerts } from './alerts';
import { drawer } from './drawer';
import { notifications } from './notifications';
import { equipmentCameras } from './equipmentCameras';
import { equipmentDevices } from './equipmentDevices';
import { equipmentInfrastructure } from './equipmentInfrastructure';
import { equipmentProtections } from './equipmentProtections';
import { settingNotifications } from './settingNotifications';
import { settingsNotifications } from './settingsNotifications';
import { settingProtectionTypes } from './settingProtectionTypes';
import { infrastructureMain } from './infrastructureMain';
import { infrastructureMap } from './infrastructureMap';
import { infrastructureMaps } from './infrastructureMaps';
import { infrastructureAreas } from './infrastructureAreas';
import { infrastructureBeacons } from './infrastructureBeacons';
import { infrastructureUsers } from './infrastructureUsers';
import { infrastructureSearch } from './infrastructureSearch';
import { infrastructureOffices } from './infrastructureOffices';
import { infrastructureSending } from './infrastructureSending';
import { staffDepartaments } from './staffDepartaments';
import { staffUsers } from './staffUsers';
import { securable } from './securable';
import { infrastructureCameras } from './InfrastructureCameras';
import { admin } from './admin';
import { reportTracks } from './reportTracks';

// add interface to avoid recursive type checking
// tslint:disable-next-line:no-empty-interface
export interface IRootModel extends Models {
  /* PLOP_INJECT_MODEL_INTERFACE */
  reportNotifications: typeof reportNotifications;
  reportPhysicalState: typeof reportPhysicalState;
  reportUseProtection: typeof reportUseProtection;
  reportWorkingTime: typeof reportWorkingTime;
  reportViolations: typeof reportViolations;
  reportStayInAreas: typeof reportStayInAreas;
  reportHeatMap: typeof reportHeatMap;
  user: typeof user;
  auth: typeof auth;
  alerts: typeof alerts;
  drawer: typeof drawer;
  notifications: typeof notifications;
  settingNotifications: typeof settingNotifications;
  settingsNotifications: typeof settingsNotifications;
  settingProtectionTypes: typeof settingProtectionTypes;
  equipmentCameras: typeof equipmentCameras;
  equipmentDevices: typeof equipmentDevices;
  equipmentInfrastructure: typeof equipmentInfrastructure;
  equipmentProtections: typeof equipmentProtections;
  infrastructureMain: typeof infrastructureMain;
  infrastructureMap: typeof infrastructureMap;
  infrastructureMaps: typeof infrastructureMaps;
  infrastructureAreas: typeof infrastructureAreas;
  infrastructureBeacons: typeof infrastructureBeacons;
  infrastructureCameras: typeof infrastructureCameras;
  infrastructureUsers: typeof infrastructureUsers;
  infrastructureSearch: typeof infrastructureSearch;
  infrastructureOffices: typeof infrastructureOffices;
  infrastructureSending: typeof infrastructureSending;
  staffDepartaments: typeof staffDepartaments;
  staffUsers: typeof staffUsers;
  securable: typeof securable;
  admin: typeof admin;
  reportTracks: typeof reportTracks;
}

const rootModel: IRootModel = {
  /* PLOP_INJECT_MODEL_OBJECT */
  reportNotifications,
  reportPhysicalState,
  reportUseProtection,
  reportWorkingTime,
  reportViolations,
  reportStayInAreas,
  reportHeatMap,
  user,
  auth,
  alerts,
  drawer,
  notifications,
  settingNotifications,
  settingsNotifications,
  settingProtectionTypes,
  equipmentCameras,
  equipmentDevices,
  equipmentInfrastructure,
  equipmentProtections,
  infrastructureMain,
  infrastructureMap,
  infrastructureMaps,
  infrastructureAreas,
  infrastructureBeacons,
  infrastructureCameras,
  infrastructureUsers,
  infrastructureSearch,
  infrastructureOffices,
  infrastructureSending,
  staffDepartaments,
  staffUsers,
  securable,
  admin,
  reportTracks
};

export default rootModel;
