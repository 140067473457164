import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import { store } from '../../../store';
import { IId } from '../../../models/types';

import apiConstants from '../../../constants/apiConstants';
const { REACT_APP_DEV_URL } = process.env;

const { Connected, Disconnected } = HubConnectionState;

const url = `${REACT_APP_DEV_URL || ''}${apiConstants.prefix}`;

const connection = new HubConnectionBuilder()
  .configureLogging(1)
  .withUrl(`${url}hubs/moves`)
  .withAutomaticReconnect({
    nextRetryDelayInMilliseconds: () => {
      return 5000;
    }
  })
  .build();

const signalRStop = () => {
  connection.stop();
};

const signalRSubscribe = async (mapId: IId) => {
  // store.dispatch.infrastructureUsers.getUsers({
  //   birthday: '2021-09-04T00:00:00',
  //   descr: 'Разработчик web приложений',
  //   email: 'anyakov3@mts.ru',
  //   id: 25,
  //   immobilizedTimeMinutes: 90,
  //   lastname: 'Яковлев',
  //   mapColor: '#FFA080',
  //   name: 'Алексей',
  //   phone: '9778008551',
  //   protectionsList: [22, 17],
  //   resetPassword: false,
  //   role: 'SUPER_ADMIN',
  //   secondname: 'Николаевич',
  //   tabularNumber: '77777',
  //   title: 'Разработчик',
  //   username: '9778008551',
  //   workshiftBreakMinutes: 70,
  //   workshiftDurationHours: 1,
  //   position: {
  //     lat: 55.69190846220035,
  //     lon: 37.65957246474376
  //   }
  // });
  if (connection.state === Disconnected) {
    await connection.start().then(() => {
      connection.invoke('SubscribeToMap', mapId);
    });
    await connection.onreconnected(error => {
      connection.invoke('SubscribeToMap', mapId);
    });
  }
  if (connection.state === Connected) {
    connection.invoke('SubscribeToMap', mapId);
  }
  connection.on('UpdateInfo', payload => {
    // tslint:disable-next-line: no-console
    console.log(payload);

    store.dispatch.infrastructureUsers.getUsers(payload);
  });
};

const signalRUnsubscribe = (mapId: IId) => {
  if (connection.state === Connected) {
    connection.invoke('UnsubscribeFromMap', mapId);
  }
};

export { signalRStop, signalRSubscribe, signalRUnsubscribe };
