import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { IBeaconType } from '../../../../models/types';

import { ReactComponent as BeaconSvg } from '../../../../assets_/icons/bleBeacon.svg';
import { ReactComponent as GatewaySvg } from '../../../../assets_/icons/bleGateway.svg';
import { ReactComponent as UwbSvg } from '../../../../assets_/icons/uwbAnchor.svg';
import { ReactComponent as InfrastructureSvg } from '../../../../assets_/icons/infrastructure.svg';

import BeaconIcon from '../../../../components_/Icon/icons/BeaconIcon';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginRight: spacing(1.5),
    fontSize: 24,
    width: '1em',
    height: '1em'
  }
}));

interface IProps {
  type: string;
  text: string;
}

const ListItemType: FC<IProps> = ({ type, text }) => {
  const classes = useStyles();

  const icon = type ? (
    type === 'BLE_BEACON' ? (
      <BeaconSvg className={classes.root} />
    ) : type === 'BLE_GATEWAY' ? (
      <GatewaySvg className={classes.root} />
    ) : type === 'UWB_ANCHOR' ? (
      <UwbSvg className={classes.root} />
    ) : (
      <InfrastructureSvg className={classes.root} />
    )
  ) : (
    <InfrastructureSvg className={classes.root} />
  );

  return (
    <Grid container alignItems="center" wrap="nowrap">
      {icon} {text}
    </Grid>
  );
};

export default ListItemType;
