import React, { FC, ReactNode } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Switch, { IProps as ISwitchProps } from '../../Fields/Switch';

import Typography from '../../Typography';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(4),
    '& > *:not(:last-child)': {
      marginRight: spacing(2)
    }
  }
}));

type IProps = {
  name: string;
  label?: ReactNode;
  useFieldConfig?: UseFieldConfig<string>;
} & ISwitchProps;

const FormSwitch: FC<IProps> = ({ name, label, useFieldConfig, ...props }) => {
  const classes = useStyles();
  const { input } = useField(name, { type: 'checkbox', ...useFieldConfig });

  // Адаптер под mts-ds
  input.onMtsChange = input.onChange;
  input.onMtsFocus = input.onFocus;
  input.onMtsBlur = input.onBlur;

  return (
    <Grid container className={classes.root}>
      <Switch {...input} {...props} />
      {label && (
        <Typography variant="p2Regular" color="text-primary">
          {label}
        </Typography>
      )}
    </Grid>
  );
};

export default FormSwitch;
