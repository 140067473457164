import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { MtsButton, MtsCard, MtsCardBody } from '@mts-ds/components-react';
import { InputSizes } from '@mts-ds/components/dist/types/components/input/enums/sizes.enum';
import { DropdownSizes } from '@mts-ds/components/dist/types/components/dropdown/enums/sizes.enum';
import { ButtonSizes, ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IProps as IFormProps } from './';
import { IUpdateOrCreateBody } from '../../../../../../services/BeaconsService';
import useRequestHandler from '../../../../../../hooks/useRequestHandler';
import { mac, required, size } from '../../../../../../utils/validation';

import Typography from '../../../../../../components_/Typography';
import Icon from '../../../../../../components_/Icon';
import FormBase from '../../../../../../components_/Form';
import FormGroup from '../../../../../../components_/Form/FormGroup';
import FormRow from '../../../../../../components_/Form/FormRow';
import FormCol from '../../../../../../components_/Form/FormCol';
import FormInput from '../../../../../../components_/Form/FormInput';
import FormDropdown from '../../../../../../components_/Form/FormDropdown';
import DrawerStickyActions from '../../../../../../components_/Drawer/DrawerStickyActions';

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    width: '100%'
  },
  card: {
    margin: spacing(3, 0)
  },
  cardBody: {
    display: 'flex',
    padding: spacing(2),
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  icon: {
    fontSize: 36
  }
}));

type IValues = IUpdateOrCreateBody;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  data: IFormProps['data'];
  item: any;
  coordinates: IFormProps['coordinates'];
  map: IFormProps['map'];
  updateOrCreate: any;
  onCancel: any;
  onSuccess: any;
  onClickRemove: any;
  isGeo: boolean;
}

const Form: FC<IProps> = ({
  data,
  item,
  coordinates,
  map,
  updateOrCreate,
  onCancel,
  onSuccess,
  onClickRemove,
  isGeo
}) => {
  const { types, offices } = data;

  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const isCreate = !Boolean(item);
  const isEdit = Boolean(item);

  const typeOptions = types.map(({ friendlyName, systemName }) => ({
    id: systemName,
    name: friendlyName
  }));

  const officeOptions = offices.map(({ name, plans }) => ({ [name]: plans }));

  const defaultValues: IValues = {
    macAddress: '',
    name: '',
    hardwareType: '',
    calibratedRssi: '',
    calibratedDistance: '',
    lat: null,
    lon: null,
    x: null,
    y: null,
    z: '',
    mapId: map.id,
    instanceId: null
  };

  const initialValues: IValues = {
    ...defaultValues,
    ...(isEdit && item)
  };

  const onSubmit = async (values: IValues) => {
    const body = { ...values, z: values.z || null };

    const error = await updateOrCreate(body);

    requestHandler({
      error,
      entity: 'beacon',
      onSuccess,
      isCreate,
      isEdit
    });
  };

  const render: IRender = ({ handleSubmit, form: { change } }) => {
    if (coordinates) {
      change(isGeo ? 'lat' : 'y', coordinates[1] as any);
      change(isGeo ? 'lon' : 'x', coordinates[0] as any);
      // null старые координаты в зависимости от режима
      change(isGeo ? 'y' : 'lat', null);
      change(isGeo ? 'x' : 'lon', null);
    }

    return (
      <FormBase onSubmit={handleSubmit}>
        <Typography variant="h3Bold">{(item && item.name) || 'Новый анкер'}</Typography>
        <MtsCard className={classes.card}>
          <MtsCardBody className={classes.cardBody}>
            <span style={{ width: '36px', height: '36px' }}>
              <Icon name="markerDrag" className={classes.icon} />
            </span>
            <Typography variant="p3Regular">
              Наведите курсор на план и установите курсор в нужной точке плана
            </Typography>
          </MtsCardBody>
        </MtsCard>
        <FormGroup title="Информация об анкере" size="s">
          <FormRow>
            <FormCol>
              <FormInput
                name="name"
                label="Название"
                placeholder="Введите название"
                size={'s' as InputSizes}
                description="Допустимое количество символов: 15"
                useFieldConfig={{ validate: value => size(value, 15) }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                name="macAddress"
                label="MAC адрес"
                placeholder="00:00:00:00:00:00"
                size={'s' as InputSizes}
                useFieldConfig={{ validate: value => required(value) || mac(value) }}
                disabled={isEdit}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormDropdown
                name="hardwareType"
                label="Тип"
                placeholder="Выберите тип"
                size={'s' as DropdownSizes}
                options={typeOptions}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                name="instanceId"
                label="Instance ID"
                tooltip="Введите Instance ID из настроек маяка"
                placeholder="Введите Instance ID"
                type="number"
                // description="Обязательное поле"
                size={'s' as InputSizes}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                name="calibratedRssi"
                label="Калибровочная сила (dBm)"
                placeholder="Введите калибровочную силу (dBm)"
                // type="number"
                // description="Обязательное поле"
                size={'s' as InputSizes}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                name="calibratedDistance"
                label="Калибровочная дистанция (м)"
                placeholder="Введите калибровочную дистанцию (м)"
                type="number"
                size={'s' as InputSizes}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormDropdown
                name="mapId"
                label="Место/План"
                placeholder="Выберите план"
                size={'s' as DropdownSizes}
                groupOptions={officeOptions}
                useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          {isGeo ? (
            <>
              <FormRow>
                <FormCol>
                  <FormInput
                    name="lat"
                    label="Широта"
                    placeholder="Введите широту"
                    size={'s' as InputSizes}
                    useFieldConfig={{ validate: required }}
                    disabled
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormInput
                    name="lon"
                    label="Долгота"
                    placeholder="Введите долготу"
                    size={'s' as InputSizes}
                    useFieldConfig={{ validate: required }}
                    disabled
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormInput
                    name="z"
                    label="Высота (необязательно)"
                    placeholder="Введите высоту"
                    size={'s' as InputSizes}
                  />
                </FormCol>
              </FormRow>
            </>
          ) : (
            <>
              <FormRow>
                <FormCol>
                  <FormInput
                    name="x"
                    label="Координата X"
                    placeholder="Введите X"
                    size={'s' as InputSizes}
                    useFieldConfig={{ validate: required }}
                    disabled
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormInput
                    name="y"
                    label="Координата Y"
                    placeholder="Введите Y"
                    size={'s' as InputSizes}
                    useFieldConfig={{ validate: required }}
                    disabled
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormInput
                    name="z"
                    label="Координата Z (необязательно)"
                    placeholder="Введите Z"
                    size={'s' as InputSizes}
                  />
                </FormCol>
              </FormRow>
            </>
          )}
        </FormGroup>
        {isEdit && (
          <MtsButton
            size={'s' as ButtonSizes}
            color={'secondary' as ButtonColors}
            onClick={onClickRemove}
            className={classes.button}
          >
            Удалить анкер
          </MtsButton>
        )}
        <DrawerStickyActions cancel="Отмена" confirm="Сохранить" onClickCancel={onCancel} />
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
