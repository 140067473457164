import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import { store } from '../../store';
import { convertNotificationToAlert } from './utils';

import apiConstants from '../../constants/apiConstants';
const { REACT_APP_DEV_URL } = process.env;

const { Disconnected } = HubConnectionState;

const url = `${REACT_APP_DEV_URL || ''}${apiConstants.prefix}`;

const connection = new HubConnectionBuilder()
  .withUrl(`${url}hubs/notifications`)
  .withAutomaticReconnect({
    nextRetryDelayInMilliseconds: () => {
      return 5000;
    }
  })
  .build();

const signalRStart = async (getAlerts: any) => {
  if (connection.state === Disconnected) {
    await connection.start().then(() => {
      connection.invoke('SubscribePlatform');
    });

    connection.on('OnNotificationMessage', payload => {
      const alert = convertNotificationToAlert(payload);
      // tslint:disable-next-line:no-console
      console.log(payload);

      store.dispatch.alerts.addAlert(alert);
    });
    await connection.onreconnected(error => {
      getAlerts();
      connection.invoke('SubscribePlatform');
    });
  }
};

const signalRStop = () => {
  connection.stop();
};

export { signalRStart, signalRStop };
