/**
 * Модель для работы с персоналом
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IUser, IRole, ISelect } from './types';

import UsersService, { IUserBody } from '../services/UsersService';
import ProtectionsService from '../services/ProtectionsService';

export interface IData {
  roles: IRole[];
  protections: ISelect[];
  brigades: ISelect[];
}

interface IList {
  data: IUser[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
  item: IUser | null;
}

const initialState: IState = {
  data: {
    roles: [],
    protections: [],
    brigades: []
  },
  list: {
    data: null,
    pagesCount: 0
  },
  item: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: payload };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    setItem(state: IState, payload: IUser): IState {
      return { ...state, item: payload };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      try {
        const [
          { data: roles },
          {
            data: { data: protections }
          },
          {
            data: { data: brigades }
          }
        ] = await Promise.all([
          UsersService.getRoles(),
          ProtectionsService.getTypeAll({ pageSize: 100 }),
          UsersService.getBrigadeAll({ pageSize: 100 })
        ]);

        this.setData({ roles, protections, brigades });
      } catch (error) {
        return error;
      }
    },
    async getList(params, rootState: iRootState) {
      try {
        const { data } = await UsersService.getAll(params);

        this.setList(data);
      } catch (error) {
        return error;
      }
    },
    async getItem(userName: string) {
      const { data } = await UsersService.get(userName);

      this.setItem(data);
    },
    async updateOrCreate(body: IUserBody) {
      try {
        await UsersService.updateOrCreate(body);
      } catch (error) {
        return error;
      }
    },
    async remove(id) {
      try {
        await UsersService.delete(id);
      } catch (error) {
        return error;
      }
    }
  }
};

export const staffUsers: typeof model = createModel(model);
