/**
 *  Модель для работы с пользователями
 */

import { createModel, ModelConfig } from '@rematch/core';
import UsersService from '../services/UsersService';
import { IUser } from './types';

// tslint:disable-next-line:no-empty-interface
interface IState extends IUser {}

const initialState: IState = {
  id: '',
  username: '',
  name: '',
  lastname: '',
  secondname: '',
  phone: '',
  email: '',
  role: '',
  title: '',
  brigadeId: null,
  brigadeName: '',
  mapColor: '',
  immobilizedTimeMinutes: 0,
  workshiftDurationHours: 0,
  workshiftBreakMinutes: 0,
  descr: '',
  lead: null,
  protectionsList: [],
  birthday: '',
  tabularNumber: '',
  photoContent: '',
  photoContentType: ''
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setUser(state: IState, payload: IUser): IState {
      return { ...state, ...payload };
    }
  },
  effects: {
    async getUser() {
      try {
        const { data } = await UsersService.getByToken({ preview: true });

        this.setUser(data);
      } catch (error) {
        return error;
      }
    }
  }
};

export const user: typeof model = createModel(model);
