/**
 * Модель для работы с пользователями
 */

import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';

import UsersService, { IUserBody } from '../services/UsersService';
import NotificationsService from '../services/NotificationsService';
import ProtectionsService from '../services/ProtectionsService';
import { iRootState, Dispatch } from '../store';
import { IId, IUser, IUserMap, INotification, ICoordXY, ISelect } from './types';

export interface IData {
  protections: ISelect[];
}

interface IState {
  item: IUser | null;
  users: IUserMap[];
  data: IData;
  notifications: INotification[];
  isFocus: boolean;
}

const initialState: IState = {
  item: null,
  users: [],
  data: {
    protections: []
  },
  notifications: [],
  isFocus: true
};

const findByUserName = (users: IUserMap[], userName: string) =>
  users.find(user => user.username === userName);

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setUser(state: IState, payload: IUser): IState {
      return {
        ...state,
        item: payload
      };
    },
    setUsers(state: IState, payload: IUserMap[]): IState {
      return {
        ...state,
        users: payload
      };
    },
    setData(state: IState, payload: IData): IState {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };
    },
    setNotifications(state: IState, payload: INotification[]): IState {
      return {
        ...state,
        notifications: payload
      };
    },
    setIsFocus(state: IState, payload: boolean): IState {
      return {
        ...state,
        isFocus: payload
      };
    }
  },
  effects: (dispatch: RematchDispatch) => {
    const d = dispatch as Dispatch;

    return {
      async getUser(userName: string, rootState: iRootState) {
        const { data } = await UsersService.get(userName);

        const { users } = rootState.infrastructureUsers;

        const dataUsers = users.map(user => {
          const { username, position, charge } = user;

          return username === userName ? { ...data, position, charge } : user;
        });

        this.setUsers(dataUsers);

        this.setUser(data);
      },
      focusUser(position: ICoordXY) {
        // const payload = {
        //   center: position,
        //   zoom: 20
        // };
        const payload = { center: position };

        d.infrastructureMap.setFocus(payload);
      },
      resetUser() {
        this.setUser(initialState.item);
      },
      async updateUser(formBody: IUserBody) {
        const { avatar, ...body } = formBody;

        const { username } = body;

        try {
          const promises = [UsersService.updateOrCreate(body)];

          if (avatar) {
            const formData = new FormData();
            formData.append('file', avatar);
            formData.append('userName', username);

            promises.push(UsersService.updateOrCreateAvatar(formData));
          }

          await Promise.all(promises);

          this.getUser(username);
        } catch (error) {
          return error;
        }
      },
      async alertUser(payload: any, rootState: iRootState) {
        const { userId, ...trackInfo } = payload;
        const { users } = rootState.infrastructureUsers;
        let data = [];
        data = users.map(user => (user.id === userId ? { ...user, ...trackInfo } : user));
        // console.log(data, payload);
        this.setUsers(data);
      },
      // Добаление пользователей на карту по SignalR
      async getUsers(payload: IUserMap, rootState: iRootState) {
        const { username, userId, trackInfo, ...userMapData } = payload;

        // Если карточка сотрудника активна, обновляем данные

        const { item } = rootState.infrastructureUsers;

        // Если есть не закрытые алерты - красим пин в красный
        const { list } = rootState.alerts;
        const isAlert = Boolean(list.find(alert => alert.userId === userId))
          ? { trackInfo: { ...trackInfo, ...{ mapColor: 'red' } } }
          : { trackInfo };
        // console.log({ ...item, ...userMapData, ...isAlert });

        if (item && item.username === username) {
          this.setUser({ ...item, ...userMapData, ...isAlert });
        }

        // ----------------------------------------------------

        const { users } = rootState.infrastructureUsers;

        const exists = findByUserName(users, username);

        let data = [];
        if (userMapData.position.type === 'Clear') {
          // Если прилетел сигнал окончания трека - удаляем пользователя
          data = users.filter(user => user.username !== username);
        } else {
          if (exists) {
            // Если пользователь есть на карте, обновляем координаты
            data = users.map(user =>
              user.username === username ? { ...user, ...userMapData, ...isAlert } : user
            );
          } else {
            // Если новый пользователь, дополнительно запрашиваем информацию и добавляем
            const { data: user } = await UsersService.get(username);

            data = [...users, { ...user, ...userMapData, ...isAlert }];
          }
        }
        // console.log(data);
        this.setUsers(data);
      },
      resetUsers() {
        this.setUsers(initialState.users);
      },
      async getData() {
        const {
          data: { data: protections }
        } = await ProtectionsService.getTypeAll({ pageSize: 100 });

        this.setData({ protections });
      },
      async getNotifications(employeesList: IId) {
        const {
          data: { data }
        } = await NotificationsService.getFullAll({ employeesList });

        this.setNotifications(data);
      },
      focusOn() {
        this.setIsFocus(true);
      },
      focusOff() {
        this.setIsFocus(false);
      }
    };
  }
};

export const infrastructureUsers: typeof model = createModel(model);
