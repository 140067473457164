/**
 * Модель для работы со структурными подразделениями
 */

import { createModel, ModelConfig } from '@rematch/core';

import { iRootState } from '../store';
import { IId, IDepartament, IUser, IShortUser } from '../models/types';
import getInitialFilter from '../utils/getInitialFilter';

import UsersService, { IBrigadeBody } from '../services/UsersService';
import { user } from './user';

interface IDataUsers {
  data: IUser[];
  pageIndex: number;
  total: number;
}

interface IData {
  users: IDataUsers;
  shortUsers: IShortUser[];
}

interface IList {
  data: IDepartament[] | null;
  pagesCount: number;
}

interface IState {
  data: IData;
  list: IList;
  item: IDepartament | null;
}

const initialState: IState = {
  data: {
    users: {
      data: [],
      pageIndex: 0,
      total: 0
    },
    shortUsers: []
  },
  list: {
    data: null,
    pagesCount: 0
  },
  item: null
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setData(state: IState, payload: IData): IState {
      return { ...state, data: { ...state.data, ...payload } };
    },
    setList(state: IState, payload: IList): IState {
      return { ...state, list: payload };
    },
    setItem(state: IState, payload: IDepartament): IState {
      return { ...state, item: payload };
    },
    reset(): IState {
      return { ...initialState };
    }
  },
  effects: {
    async getData() {
      const { data: shortUsers } = await UsersService.getAllShort();

      this.setData({ shortUsers });
    },
    async getDataUsers(params, rootState: iRootState) {
      const { users } = rootState.staffDepartaments.data;

      const {
        data: { data, total, pageIndex: page }
      } = await UsersService.getAll(params);

      this.setData({
        users: { data: [...(params.pageIndex ? users.data : []), ...data], total, pageIndex: page }
      });
    },
    async getList(params, rootState: iRootState) {
      try {
        const { data } = await UsersService.getBrigadeAll(params);

        this.setList(data);
      } catch (error) {
        return error;
      }
    },
    async getItem(id: IId) {
      try {
        const { data } = await UsersService.getBrigade(id);

        this.setItem(data);
      } catch (error) {
        return error;
      }
    },
    async updateOrCreate(body: IBrigadeBody) {
      try {
        await UsersService.updateOrCreateBrigade(body);
      } catch (error) {
        return error;
      }
    },
    async remove(id: IId) {
      try {
        await UsersService.deleteBrigade(id);
      } catch (error) {
        return error;
      }
    }
  }
};

export const staffDepartaments: typeof model = createModel(model);
