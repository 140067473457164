import React, { FC } from 'react';
import { MtsCheckbox } from '@mts-ds/components-react';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';

export type IProps = IFormGroupTextProps & typeof MtsCheckbox.defaultProps;

const Checkbox: FC<IProps> = ({ label, description, valid, ...props }) => {
  return (
    <FormGroup label={label} description={description} valid={valid}>
      <MtsCheckbox {...props} />
    </FormGroup>
  );
};

export default Checkbox;
