import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';

import { Dispatch, iRootState } from '../../store';
import { signalRStart, signalRStop } from './signalR';

import Item from './Item';
import useSound from 'use-sound';
import alertSound from '../../assets/sounds/alert.wav';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'fixed',
    top: 78,
    right: 24,
    width: 380,
    zIndex: 10000,
    '& > *:not(:last-child)': {
      marginBottom: spacing(1)
    }
  }
}));

const mapState = (state: iRootState) => ({
  alerts: state.alerts.list,
  soundEnabled: state.alerts.soundEnabled
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getAlerts: d.alerts.getAlerts
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const Alerts: FC<IProps> = ({ getAlerts, alerts, soundEnabled }) => {
  const classes = useStyles();

  const [play, { stop }] = useSound(alertSound, {
    loop: 1,
    interrupt: true,
    volume: Number(soundEnabled)
  });

  const listSize = 3;

  useEffect(() => {
    signalRStart(getAlerts);
    getAlerts();

    return signalRStop;
  }, []);

  return (
    <Grid className={classes.root}>
      {alerts.slice(0, listSize).map(alert => (
        <Item key={alert.uid} alert={alert} play={play} stop={stop} />
      ))}
    </Grid>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Alerts);
