import React, { FC, useState } from 'react';
import { WMSTileLayer as LeafletWMSTileLayer } from 'react-leaflet';

import mtsGeoConstants from '../../../constants/mtsGeoConstants';
import Grid from '@material-ui/core/Grid';
import Icon from '../../../components_/Icon';
import { MtsRadio, MtsRadioGroup } from '@mts-ds/components-react';
import { RadioSizes } from '@mts-ds/components/dist/types/components/radio/enums/sizes.enum';
import Typography from '../../../components_/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Dispatch, iRootState } from '../../../store';
import { connect } from 'react-redux';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    borderRadius: 8,
    border: '2px solid #BCC3D080',
    padding: 8,
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF)'
  },
  radio: {
    margin: 3
  }
}));

const mapState = (state: iRootState) => ({
  mapKeys: state.admin.mapKeys
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {};
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;
const WMSTileLayer: FC<IProps> = ({ mapKeys }) => {
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  const WMST = localStorage.getItem('WMST');
  const [mapLayer, setMapLayer] = useState(WMST ? WMST : 'tomtom');
  const { tomtom, egis } = mapKeys;

  const onChange = (e: any) => {
    // console.log(e.target.value);
    localStorage.setItem('WMST', e.target.value);
    setMapLayer(e.target.value);
  };
  const TileLayer = () => {
    switch (mapLayer) {
      case 'tomtom':
        // console.log(value);
        return (
          <LeafletWMSTileLayer
            url={'https://{s}.api.tomtom.com/map/1/wms/?key=' + tomtom}
            format="png"
            layers="basic"
            view="RU"
            language="ru-RU"
            tileSize={1024}
          />
        );
      case 'mtsGEO':
        // console.log(value);
        return (
          <LeafletWMSTileLayer
            {...mtsGeoConstants}
            authKey={egis}
            tiled
            transparent
            format="image/png8"
            layers="substrate:map"
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className="leaflet-bottom leaflet-left"
      >
        <div className="leaflet-control">
          <Grid
            className={classes.container}
            container
            justify="center"
            alignItems="center"
            wrap="nowrap"
          >
            {!isHovering ? (
              <Icon name={'layers'} />
            ) : (
              <MtsRadioGroup onMtsChange={onChange} value={mapLayer} size={'s' as RadioSizes}>
                <Grid container alignItems="center">
                  <MtsRadio value="tomtom" className={classes.radio} />
                  <Typography variant="p3Regular">TomTom</Typography>
                </Grid>
                <Grid container alignItems="center">
                  <MtsRadio value="mtsGEO" className={classes.radio} />
                  <Typography variant="p3Regular">MTSGeo</Typography>
                </Grid>
              </MtsRadioGroup>
            )}
          </Grid>
        </div>
      </div>
      <TileLayer />
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(WMSTileLayer);
