import React, { FC } from 'react';

import { InputSizes } from '@mts-ds/components/dist/types/components/input/enums/sizes.enum';
import { DropdownSizes } from '@mts-ds/components/dist/types/components/dropdown/enums/sizes.enum';

import FormGroup from '../../../../../../components_/Form/FormGroup';
import FormRow from '../../../../../../components_/Form/FormRow';
import FormCol from '../../../../../../components_/Form/FormCol';
import FormInput from '../../../../../../components_/Form/FormInput';
import FormDropdown from '../../../../../../components_/Form/FormDropdown';

import { IProps as IFormProps } from './Form';

interface IProps {
  data: IFormProps['data'];
}

const FormPersonal: FC<IProps> = ({ data }) => {
  const { brigades, protections } = data;

  const brigadeOptions = brigades.map(({ id, name }) => ({ id, name }));
  const protectionOptions = protections.map(({ id, name }) => ({ id: String(id), name }));

  return (
    <FormGroup title="Рабочая информация" size="s">
      <FormRow>
        <FormCol>
          <FormInput
            name="title"
            label="Должность (необязательно)"
            placeholder="Введите должность"
            size={'s' as InputSizes}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormDropdown
            name="brigadeId"
            label="Структурное подразделение (необязательно)"
            placeholder="Выберите подразделение"
            size={'s' as DropdownSizes}
            options={brigadeOptions}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormInput
            name="tabularNumber"
            label="Табельный номер (необязательно)"
            placeholder="Введите табельный номер"
            size={'s' as InputSizes}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormDropdown
            name="protectionsList"
            label="Обязательная экипировка (необязательно)"
            placeholder="Выберите экипировку"
            size={'s' as DropdownSizes}
            multiple
            options={protectionOptions}
          />
        </FormCol>
      </FormRow>
    </FormGroup>
  );
};

export default FormPersonal;
