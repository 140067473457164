import React, { FC, ReactElement, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';

import { iRootState, Dispatch } from '../../../store';
import { ReactComponent as BeaconSvg } from '../../../assets_/icons/bleBeacon.svg';
import { ReactComponent as GatewaySvg } from '../../../assets_/icons/bleGateway.svg';
import { ReactComponent as UwbSvg } from '../../../assets_/icons/uwbAnchor.svg';
import { ReactComponent as InfrastructureSvg } from '../../../assets_/icons/infrastructure.svg';
import { IBeacon, ICoordXY } from '../../../models/types';
import { getColor } from '../../../theme';
import { paths } from '../../Root/paths';

import BeaconMarkerListItemTooltip from './BeaconMarkerListItemTooltip';

const useStyles = makeStyles(() => ({
  icon: ({ hidden }: IStyleProps) => ({
    color: hidden ? getColor('constant-greyscale-500') : getColor('constant-blueberry-light'),
    fontSize: 32,
    width: '1em',
    height: '1em'
  })
}));

const mapState = (state: iRootState) => ({
  activeItem: state.infrastructureBeacons.item,
  office: state.infrastructureOffices.item,
  isGeo: state.infrastructureMap.layers.isGeo
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    focus: d.infrastructureBeacons.focus
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

interface IStyleProps {
  hidden?: boolean;
  edit?: boolean;
}

type IProps = {
  item: IBeacon | null | undefined;
} & IStyleProps &
  connectedProps;

const BeaconMarkerListItem: FC<IProps> = ({
  item,
  hidden,
  edit,
  activeItem,
  focus,
  office,
  isGeo
}) => {
  const classes = useStyles({ hidden });
  const history = useHistory();

  const { macAddress, lat = 0, lon = 0, x = 0, y = 0 } = item || {};

  const position: ICoordXY = isGeo ? [lat, lon] : [y, x];

  useEffect(() => {
    if (activeItem && activeItem.macAddress === macAddress) {
      focus(position);
    }
  }, [activeItem]);

  const handleClick = () => {
    history.push(paths.map.office.infrastructure.show(macAddress));
  };

  const renderIcon = edit ? (
    <InfrastructureSvg className={classes.icon} />
  ) : item ? (
    item.hardwareType === 'BLE_BEACON' ? (
      <BeaconSvg className={classes.icon} />
    ) : item.hardwareType === 'BLE_GATEWAY' ? (
      <GatewaySvg className={classes.icon} />
    ) : item.hardwareType === 'UWB_ANCHOR' ? (
      <UwbSvg className={classes.icon} />
    ) : (
      <InfrastructureSvg className={classes.icon} />
    )
  ) : (
    <InfrastructureSvg className={classes.icon} />
  );

  const icon = divIcon({
    className: undefined,
    iconSize: undefined,
    iconAnchor: [15, 15],
    html: item ? renderToString(renderIcon) : ''
  });

  return (
    <Marker
      zIndexOffset={edit ? 1000 : 0}
      position={position}
      icon={icon}
      onClick={handleClick}
      noRemove
    >
      {item && <BeaconMarkerListItemTooltip item={item} />}
    </Marker>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(BeaconMarkerListItem);
