import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useMtsModal } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../../../store';
import { useAsync, useRequestHandler } from '../../../../../hooks';
import { paths } from '../../../../Root/paths';

import Spinner from '../../../../../components_/Spinner';
import Modal from '../../../../../components_/Modal';

import Form from './Form';

const mapState = (state: iRootState) => ({
  item: state.infrastructureOffices.item,
  coordinates: state.infrastructureOffices.coordinates
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getOffice: d.infrastructureOffices.getOffice,
    updateOrCreate: d.infrastructureOffices.updateOrCreate,
    remove: d.infrastructureOffices.remove,
    reset: d.infrastructureOffices.reset,
    setMarkerCreateMode: d.infrastructureMap.setMarkerCreateMode,
    setMarkerEditMode: d.infrastructureMap.setMarkerEditMode,
    setDefaultMode: d.infrastructureMap.setDefaultMode,
    setLayers: d.infrastructureMap.setLayers
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

interface IParams {
  id: string;
}

const OfficeShow: FC<IProps> = ({
  item,
  coordinates,
  getOffice,
  updateOrCreate,
  remove,
  setDefaultMode,
  setLayers,
  setMarkerCreateMode,
  setMarkerEditMode
}) => {
  const requestHandler = useRequestHandler();
  const history = useHistory();
  const { id } = useParams<IParams>();
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getOffice);

  const handleCancel = () => {
    history.goBack();
  };

  const handleSuccess = () => {
    history.goBack();
  };

  const [openRemoveModal] = useMtsModal(Modal, {
    header: 'Удалить место',
    text:
      'Вы действительно хотите удалить место? С удалением места будут удалены все связааные с ним элементы.',
    buttonTextConfirm: 'Удалить',
    ...(item && {
      onMtsModalConfirm: async () => {
        const error = await remove(item.id);

        requestHandler({
          error,
          entity: 'office',
          onSuccess: handleSuccess,
          isDelete: true
        });
      }
    })
  });

  useEffect(() => {
    return () => {
      setDefaultMode();
      // reset();
    };
  }, []);

  useEffect(() => {
    if (id) {
      setLayers({ isGeo: true });
      runGetItem(id);
      setMarkerEditMode();
    }
  }, [id]);

  const isLoading = isLoadingGetItem;

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Form
          item={item}
          coordinates={coordinates}
          updateOrCreate={updateOrCreate}
          onCancel={handleCancel}
          onSuccess={handleSuccess}
          onClickRemove={openRemoveModal}
        />
      )}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(OfficeShow);
