import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../store';
import { signalRSubscribe, signalRUnsubscribe, signalRStop } from './userMarkerListSignalR';

import UserMarkerGroup from './UserMarkerGroup';
import UserMarkerListItem from './UserMarkerListItem';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  users: state.infrastructureUsers.users,
  layers: state.infrastructureMap.layers
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {};
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const UserMarkerList: FC<IProps> = ({ users, map, layers }) => {
  const { id } = map;
  const { isUsers } = layers;

  useEffect(() => {
    return () => {
      signalRStop();
    };
  }, []);

  useEffect(() => {
    if (id) {
      signalRSubscribe(id);

      return () => {
        signalRUnsubscribe(id);
      };
    }
  }, [id]);

  return (
    <UserMarkerGroup>
      {isUsers && users.map(user => <UserMarkerListItem key={user.username} user={user} />)}
    </UserMarkerGroup>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(UserMarkerList);
