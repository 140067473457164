import React, { FC } from 'react';

import { IUser } from '../../../../models/types';
import getFullName from '../../../../utils/getFullName';

import Table from '../../../../components_/Table';
import TableColLink from '../../../../components_/Table/TableColLink';
import TableColText from '../../../../components_/Table/TableColText';
import TableActionGroup from '../../../../components_/Table/TableActionGroup';
import TableActionButton from '../../../../components_/Table/TableActionButton';

export interface IProps {
  list: IUser[];
  onClickEdit: any;
  onClickDelete: any;
}

const List: FC<IProps> = ({ list, onClickEdit, onClickDelete }) => {
  const rows = list.map(item => ({
    items: {
      fio: (
        <TableColText onClick={onClickEdit(item)}>
          <TableColLink>{getFullName(item)}</TableColLink>
        </TableColText>
      ),
      title: <TableColText>{item.title}</TableColText>,
      brigadeName: <TableColText>{item.brigadeName}</TableColText>,
      actions: (
        <TableActionGroup>
          <TableActionButton icon="edit" tooltip="Редактировать" onClick={onClickEdit(item)} />
          <TableActionButton icon="bin" tooltip="Удалить" onClick={onClickDelete(item)} />
        </TableActionGroup>
      )
    }
  }));

  const cols = [
    {
      title: 'ФИО',
      field: 'fio'
    },
    {
      title: 'Должность',
      field: 'title'
    },
    {
      title: 'Структурное подразделение',
      field: 'brigadeName'
    },
    {
      title: 'Номер метки',
      field: ''
    },
    {
      title: 'Заряд батареи',
      field: ''
    },
    {
      title: '',
      field: 'actions'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
