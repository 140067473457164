import React, { FC } from 'react';
import { CircleMarker } from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { MtsBadge } from '@mts-ds/components-react';
import Typography from '../../../components_/Typography';
import MarkerTooltip from '../../Main/Map/components/MarkerTooltip';
import { getColor } from '../../../theme';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  time: {
    paddingTop: '10px',
    paddingBottom: '10px'
  }
}));

interface IProps {
  points: any;
}

const StartAndFinishMarker: FC<IProps> = ({ points }) => {
  const classes = useStyles();
  return (
    <>
      <CircleMarker
        center={points[0].location}
        radius={6}
        weight={3}
        color={getColor('constant-blackberry-light')}
        fillColor={'white'}
        fillOpacity={1}
        key={'start'}
      >
        <MarkerTooltip>
          <Grid>
            <MtsBadge size="s">Старт</MtsBadge>
            <Typography className={classes.time} variant="p2Bold">{`${moment(points[0].time).format(
              'L HLTS'
            )}`}</Typography>
            <Typography variant="p3Regular">{`Широта ${points[0].location.lat}`}</Typography>
            <Typography variant="p3Regular">{`Долгота ${points[0].location.lon}`}</Typography>
            <Typography variant="p3Regular">{`Высота ${points[0].location.alt}`}</Typography>
          </Grid>
        </MarkerTooltip>
      </CircleMarker>
      <CircleMarker
        center={points[points.length - 1].location}
        radius={6}
        weight={3}
        color={getColor('constant-blackberry-light')}
        fillColor={'white'}
        fillOpacity={1}
        key={'finish'}
      >
        <MarkerTooltip>
          <Grid>
            <MtsBadge size="s">Финиш</MtsBadge>
            <Typography className={classes.time} variant="p2Bold">{`${moment(
              points[points.length - 1].time
            ).format('L HLTS')}`}</Typography>
            <Typography variant="p3Regular">{`Широта ${points[points.length - 1].location.lat}`}</Typography>
            <Typography variant="p3Regular">{`Долгота ${points[points.length - 1].location.lon}`}</Typography>
            <Typography variant="p3Regular">{`Высота ${points[points.length - 1].location.alt}`}</Typography>
          </Grid>
        </MarkerTooltip>
      </CircleMarker>
    </>
  );
};

export default StartAndFinishMarker;
