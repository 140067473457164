import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { paths } from '../../routes/Root/paths';

import Content from '../../components_/Content';

import Tabs from './Tabs';

import NotificationsForm from './Notifications/Form';
import NotificationsList from './Notifications/List';
import ProtectionTypesForm from './ProtectionTypes/Form';
import ProtectionTypesList from './ProtectionTypes/List';

const Settings: FC = () => {
  return (
    <Switch>
      {/* Формы */}
      <Route
        path={[paths.settings.notifications.create, paths.settings.notifications.edit()]}
        component={NotificationsForm}
      />
      <Route
        path={[paths.settings.protectionTypes.create, paths.settings.protectionTypes.edit()]}
        component={ProtectionTypesForm}
      />

      {/* Списки */}
      <Route path={paths.settings.root}>
        <Content title="Настройки">
          <Tabs />
          <Switch>
            <Route exact path={paths.settings.root} component={NotificationsList} />
            <Route
              exact
              path={paths.settings.protectionTypes.root}
              component={ProtectionTypesList}
            />
          </Switch>
        </Content>
      </Route>
    </Switch>
  );
};

export default Settings;
