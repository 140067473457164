import React, { FC } from 'react';
import { renderToString } from 'react-dom/server';
import { divIcon, MarkerCluster } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { makeStyles, Grid } from '@material-ui/core';

import { mapColor } from '../../../theme';

const useStyles = makeStyles(() => ({
  point: {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: mapColor.darkOrange,
    width: 28,
    height: 28,
    fontWeight: 500,
    color: mapColor.darkGray
  },
  count: {
    borderRadius: '50%',
    width: 16,
    height: 16
  }
}));

const UserMarkerGroup: FC = ({ children }) => {
  const classes = useStyles();

  const renderIcon = (count: number) => (
    <Grid container justify="center" alignItems="center" className={classes.point}>
      <Grid item container justify="center" alignItems="center" className={classes.count}>
        {count}
      </Grid>
    </Grid>
  );

  const icon = (cluster: MarkerCluster) => {
    const count = cluster.getChildCount();

    return divIcon({
      iconSize: undefined,
      html: renderToString(renderIcon(count))
    });
  };

  return (
    <MarkerClusterGroup maxClusterRadius={50} iconCreateFunction={icon}>
      {children}
    </MarkerClusterGroup>
  );
};

export default UserMarkerGroup;
