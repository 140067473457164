import React, { FC, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { MtsButton, MtsCard, MtsCardBody } from '@mts-ds/components-react';
import { InputSizes } from '@mts-ds/components/dist/types/components/input/enums/sizes.enum';
import { ButtonSizes, ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IProps as IFormProps } from './';
import { IUpdateOrCreateBody } from '../../../../../../services/CamerasService';
import useRequestHandler from '../../../../../../hooks/useRequestHandler';
import { numMax, required, size } from '../../../../../../utils/validation';

import Typography from '../../../../../../components_/Typography';
import Icon from '../../../../../../components_/Icon';
import FormBase from '../../../../../../components_/Form';
import FormGroup from '../../../../../../components_/Form/FormGroup';
import FormRow from '../../../../../../components_/Form/FormRow';
import FormCol from '../../../../../../components_/Form/FormCol';
import FormInput from '../../../../../../components_/Form/FormInput';
import DrawerStickyActions from '../../../../../../components_/Drawer/DrawerStickyActions';

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    width: '100%'
  },
  card: {
    margin: spacing(3, 0)
  },
  cardBody: {
    display: 'flex',
    padding: spacing(2),
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  icon: {
    fontSize: 36
  }
}));

type IValues = IUpdateOrCreateBody;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  item: any;
  coordinates: IFormProps['coordinates'];
  map: IFormProps['map'];
  updateOrCreate: any;
  onCancel: any;
  onSuccess: any;
  onClickRemove: any;
  isGeo: boolean;
  setItemEdit: any;
  cameraEdit: any;
}

const Form: FC<IProps> = ({
  item,
  coordinates,
  map,
  updateOrCreate,
  onCancel,
  onSuccess,
  onClickRemove,
  isGeo,
  setItemEdit,
  cameraEdit
}) => {
  const classes = useStyles();
  const requestHandler = useRequestHandler();

  const isCreate = !Boolean(item);
  const isEdit = Boolean(item);

  const defaultValues: IValues = {
    id: null,
    name: '',
    focalLength: null,
    rotationAngle: null,
    description: '',
    isNotificationEnabled: true,
    lat: null,
    lon: null,
    alt: null,
    coordinateX: null,
    coordinateY: null,
    coordinateZ: null,
    planId: map.id
  };

  const initialValues: IValues = {
    ...defaultValues,
    ...(isEdit && item),
    ...(isEdit && item && item.location)
  };

  const onSubmit = async (values: IValues) => {
    const body = {
      // ...values,
      id: values.id,
      name: values.name,
      planId: values.planId,
      focalLength: values.focalLength,
      rotationAngle: values.rotationAngle,
      description: values.description,
      isNotificationEnabled: values.isNotificationEnabled,
      location:
        {
          lat: values.lat,
          lon: values.lon,
          alt: values.alt,
          coordinateX: values.coordinateX,
          coordinateY: values.coordinateY,
          coordinateZ: values.coordinateZ
        } || null
    };
    const error = await updateOrCreate(body);

    requestHandler({
      error,
      entity: 'camera',
      onSuccess,
      isCreate,
      isEdit
    });
  };

  const render: IRender = ({ handleSubmit, form: { change } }) => {
    if (coordinates) {
      change(isGeo ? 'lat' : 'coordinateY', coordinates[1] as any);
      change(isGeo ? 'lon' : 'coordinateX', coordinates[0] as any);
      // null старые координаты в зависимости от режима
      change(isGeo ? 'coordinateY' : 'lat', null);
      change(isGeo ? 'coordinateX' : 'lon', null);
    }

    return (
      <FormBase onSubmit={handleSubmit}>
        <FormSpy
          // subscription={{ pristine: true, values: true, active: true, validating: true }}
          onChange={props => {
            // console.log(props);
            if (props.active === 'lat' || props.active === 'lon') {
              // console.log(
              //   {
              //     ...cameraEdit,
              //     location:
              //       {
              //         lat: props.values.lat,
              //         lon: props.values.lon,
              //         alt: props.values.alt,
              //         coordinateX: props.values.coordinateX,
              //         coordinateY: props.values.coordinateY,
              //         coordinateZ: props.values.coordinateZ
              //       } || null
              //   },
              //   props
              // );
              setItemEdit({
                ...cameraEdit,
                location:
                  {
                    lat: props.values.lat,
                    lon: props.values.lon,
                    alt: props.values.alt,
                    coordinateX: props.values.coordinateX,
                    coordinateY: props.values.coordinateY,
                    coordinateZ: props.values.coordinateZ
                  } || null
              });
            }
          }}
        />
        <Typography variant="h3Bold">{(item && item.name) || 'Новая камера'}</Typography>
        <MtsCard className={classes.card}>
          <MtsCardBody className={classes.cardBody}>
            <span style={{ width: '36px', height: '36px' }}>
              <Icon name="cameraDrag" className={classes.icon} />
            </span>
            <Typography variant="p3Regular">
              {coordinates || initialValues.coordinateX
                ? 'Поставтье курсор на маркер и перетащите его в нужную точку плана'
                : 'Наведите курсор на план и щелчком установите камеру в нужной точке'}
            </Typography>
          </MtsCardBody>
        </MtsCard>
        <FormGroup title="Информация об камере" size="s">
          <FormRow>
            <FormCol>
              <FormInput
                id="inputName"
                name="name"
                label="Название"
                tooltip="Идентификатор камеры во внешней системе"
                placeholder="Например, pv-cam-01"
                size={'s' as InputSizes}
                description="Допустимое количество символов: 15"
                useFieldConfig={{ validate: value => required(value) || size(value, 15) }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                id="inputRotationAngle"
                name="rotationAngle"
                label="Угол поворота (градус°)"
                tooltip="Азимут - угол поворота между севером и направлением камеры"
                placeholder="Например, 0"
                type="number"
                size={'s' as InputSizes}
                useFieldConfig={{ validate: value => required(value) || numMax(value, 360) }}
              />
            </FormCol>
          </FormRow>
          {isGeo ? (
            <>
              <FormRow>
                <FormCol>
                  <FormInput
                    id="inputLat"
                    name="lat"
                    label="Широта"
                    placeholder="Введите широту"
                    size={'s' as InputSizes}
                    useFieldConfig={{ validate: required }}
                    disabled
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormInput
                    id="inputLon"
                    name="lon"
                    label="Долгота"
                    placeholder="Введите долготу"
                    size={'s' as InputSizes}
                    useFieldConfig={{ validate: required }}
                    disabled
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormInput
                    id="inputAlt"
                    name="alt"
                    label="Высота (необязательно)"
                    placeholder="Введите высоту"
                    size={'s' as InputSizes}
                    // useFieldConfig={{ validate: required }}
                    // disabled
                  />
                </FormCol>
              </FormRow>
            </>
          ) : (
            <>
              <FormRow>
                <FormCol>
                  <FormInput
                    id="inputCoordinateX"
                    name="coordinateX"
                    label="Координата X"
                    placeholder="Введите X"
                    size={'s' as InputSizes}
                    useFieldConfig={{ validate: required }}
                    disabled
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormInput
                    id="inputCoordinateY"
                    name="coordinateY"
                    label="Координата Y"
                    placeholder="Введите Y"
                    size={'s' as InputSizes}
                    useFieldConfig={{ validate: required }}
                    disabled
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <FormInput
                    id="inputCoordinateZ"
                    name="coordinateZ"
                    label="Координата Z (необязательно)"
                    placeholder="Введите Z"
                    size={'s' as InputSizes}
                    // useFieldConfig={{ validate: required }}
                    // disabled
                  />
                </FormCol>
              </FormRow>
            </>
          )}
          <FormRow>
            <FormCol>
              <FormInput
                id="inputFocalLength"
                name="focalLength"
                label="ЭФР (мм) (необязательно)"
                tooltip="Эквивалентное фокусное расстояние"
                type="number"
                placeholder="Например, 15"
                useFieldConfig={{ validate: value => numMax(value, 1600) }}
                size={'s' as InputSizes}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                id="inputDescription"
                name="description"
                label="Комментарий (необязательно)"
                placeholder="Введите"
                // description="Обязательное поле"
                size={'s' as InputSizes}
              />
            </FormCol>
          </FormRow>
        </FormGroup>
        {isEdit && (
          <MtsButton
            id="CameraButtonRemove"
            size={'s' as ButtonSizes}
            color={'secondary' as ButtonColors}
            onClick={onClickRemove}
            className={classes.button}
          >
            Удалить камеру
          </MtsButton>
        )}
        <DrawerStickyActions cancel="Отмена" confirm="Сохранить" onClickCancel={onCancel} />
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
