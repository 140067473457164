import { IBeaconType } from './models/types';
import { mapColor, getColor } from './theme';

export type IRole = 'USER' | 'ADMIN' | 'SUPER_ADMIN';

export type IProtectionTechnologyTypes = 'NFC' | 'QR' | 'MAC';

export type IProtectionUseTypes = 'USER_PERMANENT' | 'USER_TEMPORARY';

export type IBeaconStatuses = 'true' | 'false';

export type IMinutesStep = 0 | 10 | 20 | 30 | 40 | 50;

export type IHoursStep = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface IStatusItem<T> {
  id: T;
  name: string;
}

export interface IStatus<T> {
  [key: string]: IStatusItem<T>;
}

export interface INotificationTypesDataItem {
  icon: string;
  color: string;
  send: boolean;
}

export interface INotificationTypesData {
  [key: string]: INotificationTypesDataItem;
}

/**
 * Размер страницы
 */
const defaultPageSize = 20;

/**
 * Роли пользователя
 */
const userRole: IStatus<IRole> = {
  USER: { id: 'USER', name: 'Пользователь' },
  ADMIN: { id: 'ADMIN', name: 'Администратор' },
  SUPER_ADMIN: { id: 'SUPER_ADMIN', name: 'Супер админ' }
};

/**
 * Типы анкеров
 */

type IBeaconTypes<T extends string> = {
  [key in T]: {
    id: T;
    name: T;
    color: string;
  };
};

/**
 * Типы технологий СИЗ
 */
const protectionTechnologyTypes: IStatus<IProtectionTechnologyTypes> = {
  NFC: { id: 'NFC', name: 'NFC' },
  QR: { id: 'QR', name: 'QR' },
  MAC: { id: 'MAC', name: 'MAC' }
};

/**
 *  Типы использования СИЗ
 */
const protectionUseTypes: IStatus<IProtectionUseTypes> = {
  USER_TEMPORARY: { id: 'USER_TEMPORARY', name: 'Временное' },
  USER_PERMANENT: { id: 'USER_PERMANENT', name: 'Постоянное' }
};

/**
 *  Типы использования СИЗ
 */
const beaconStatuses: IStatus<IBeaconStatuses> = {
  true: { id: 'true', name: 'Активное' },
  false: { id: 'false', name: 'Неактивное' }
};

/**
 * Данные для типов уведомлений
 */
const notificationTypesData: INotificationTypesData = {
  // Аварийная ситуация
  NotificationSos: {
    icon: 'notificationSos',
    color: '#ba360a',
    send: true
  },
  // Вхождение в опасную зону
  NotificationEnteringDangerZone: {
    icon: 'notificationEnteringDangerZone',
    color: '#F37F19',
    send: false
  },
  // Выход из опасной зоны
  NotificationLeaveDangerZone: {
    icon: 'notificationLeaveDangerZone',
    color: '#F37F19',
    send: false
  },
  // Забытый СИЗ
  NotificationForgottenProtection: {
    icon: 'notificationForgottenProtection',
    color: '#f37f19',
    send: false
  },
  // Обездвижен
  NotificationImmobilized: {
    icon: 'notificationImmobilized',
    color: '#f37f19',
    send: false
  },
  // Падение с высоты
  NotificationFall: {
    icon: 'notificationFall',
    color: '#ba360a',
    send: false
  },
  // Информационное сообщени
  NotificationInformational: {
    icon: 'notificationInformational',
    color: '#0097fd',
    send: false
  },
  // Начало рабочей смены
  NotificationWorkStart: {
    icon: 'notificationWorkStart',
    color: '#0097fd',
    send: false
  },
  // Окончание рабочей смены
  NotificationWorkEnd: {
    icon: 'notificationWorkEnd',
    color: '#0097fd',
    send: false
  },
  // Авария на производстве
  NotificationEmergency: {
    icon: 'notificationEmergency',
    color: '#ba360a',
    send: true
  },
  // Превышение пульса
  NotificationHealthPulseAbnormal: {
    icon: 'notificationHealthPulseAbnormal',
    color: '#f37f19',
    send: false
  },
  // Превышение допустимого времени нахождения в зоне
  NotificationAreaExceedingTime: {
    icon: 'notificationAreaExceedingTime',
    color: '#fac031',
    send: false
  },
  // Нахождение в зоне в нерабочее время
  NotificationAreaOfftime: {
    icon: 'notificationAreaOfftime',
    color: '#fac031',
    send: false
  },
  // Проблема с экипировкой
  NotificationProtectionProblem: {
    icon: 'notificationProtectionProblem',
    color: '#fac031',
    send: false
  },
  // Нарушение доступа в зону
  NotificationAreaAccessViolation: {
    icon: 'notificationAreaAccessViolation',
    color: '#f37f19',
    send: false
  }
};

/**
 * Время в зоне
 */
const minutesStep: IStatus<IMinutesStep> = {
  0: {
    id: 0,
    name: 'Минуты'
  },
  10: {
    id: 10,
    name: '10 минут'
  },
  20: {
    id: 20,
    name: '20 минут'
  },
  30: {
    id: 30,
    name: '30 минут'
  },
  40: {
    id: 40,
    name: '40 минут'
  },
  50: {
    id: 50,
    name: '50 минут'
  }
};

const hoursStep: IStatus<IHoursStep> = {
  0: {
    id: 0,
    name: 'Часы'
  },
  1: {
    id: 1,
    name: '1 час'
  },
  2: {
    id: 2,
    name: '2 часа'
  },
  3: {
    id: 3,
    name: '3 часа'
  },
  4: {
    id: 4,
    name: '4 часа'
  },
  5: {
    id: 5,
    name: '5 часов'
  },
  6: {
    id: 6,
    name: '6 часов'
  },
  7: {
    id: 7,
    name: '7 часов'
  },
  8: {
    id: 8,
    name: '8 часов'
  },
  9: {
    id: 9,
    name: '9 часов'
  },
  10: {
    id: 10,
    name: '10 часов'
  },
  11: {
    id: 11,
    name: '11 часов'
  },
  12: {
    id: 12,
    name: '12 часов'
  }
};

export {
  defaultPageSize,
  userRole,
  protectionTechnologyTypes,
  protectionUseTypes,
  beaconStatuses,
  notificationTypesData,
  minutesStep,
  hoursStep
};
