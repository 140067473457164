import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState } from '../../../store';
import { paths } from '../../Root/paths';
import { useAsync, useParams, useQueryParams } from '../../../hooks';
import Drawer from '../../../components/Drawer';

import Content from '../../../components_/Content';
import Spinner from '../../../components_/Spinner';
import EmptyState from '../../../components_/EmptyState';

import DrawerContent from '../../../components_/Drawer/DrawerContent';
import { MtsBreadcrumb, MtsBreadcrumbItem, MtsButton } from '@mts-ds/components-react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import FormRow from '../../../components_/Form/FormRow';
import FormCol from '../../../components_/Form/FormCol';
import InputWithDate from '../../../components_/Fields/DatePicker/inputWithDate/inputWithDate';
import Dropdown from '../../../components_/Fields/Dropdown';
import Typography from '../../../components_/Typography';
import Item from './item';
import Map from './Map';
import { Grid } from '@material-ui/core';

import FormDoubleField from '../../../components_/Form/FormDoubleField';
import TimePicker from '../../../components_/Fields/TimePicker';

const mapState = (state: iRootState) => ({
  data: state.reportTracks.data,
  filter: state.reportTracks.filter,
  tracks: state.reportTracks.list,
  track: state.reportTracks.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.reportTracks.getData,
    setFilter: d.reportTracks.setFilter,
    resetFilter: d.reportTracks.resetFilter,
    getTracks: d.reportTracks.getTracks,
    resetTracks: d.reportTracks.resetTracks,
    resetTrack: d.reportTracks.resetTrack
  };
};

const useStyles = makeStyles(({ spacing }) => ({
  breadcrumb: {
    marginTop: spacing(2),
    marginBottom: spacing(1)
  },
  h1: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  icon: {
    backgroundColor: 'white',
    boxShadow: '0px 8px 24px #E7EAFA',
    borderRadius: '50%',
    width: 64,
    height: 64,
    marginBottom: spacing(2)
  },
  button: {
    marginTop: spacing(2)
  },
  desc: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    color: '#626C77'
  },
  filter: {
    marginBottom: 10
  }
}));

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const HeatMap: FC<IProps> = ({
  getData,
  setFilter,
  resetFilter,
  getTracks,
  resetTracks,
  resetTrack,
  data,
  filter,
  tracks,
  track
}) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetTracks, isLoadingGetTracks, isGetTracksLoadingError] = useAsync(getTracks);
  const history = useHistory();
  const classes = useStyles();
  const { brigades } = data;
  const { setQueryParams } = useQueryParams();
  const { trackUsers, trackFrom, trackTo, trackTimeFrom, trackTimeTo } = filter;
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFilter({ [name]: value });
  };

  const handleClick = () => {
    resetTrack();
    runGetTracks();
    setQueryParams(filter);
  };

  useEffect(() => {
    runGetData();

    return () => {
      resetTrack();
    };
  }, []);

  const handleBreadcrumbItemClick = (url: string) => () => {
    history.push(url);
  };

  const isLoading = isLoadingGetData || isLoadingGetTracks;
  const isLoadingError = isGetDataLoadingError || isGetTracksLoadingError;
  const brigadeOptions = brigades.map(({ name, employees }) => ({
    [name]: employees && employees.map(({ userId, fio }) => ({ id: userId, name: fio }))
  }));

  const title = 'Треки';

  return (
    <>
      {!isLoading && !isLoadingError && (
        <>
          {!track && (
            <>
              <Grid item xs container direction="column" justify="center" alignItems="center">
                {/*<Grid*/}
                {/*  container*/}
                {/*  justify="center"*/}
                {/*  alignItems="center"*/}
                {/*  className={classes.icon}*/}
                {/*></Grid>*/}
                <Typography variant="p2Regular" color="text-tertiary" className={classes.desc}>
                  Отслеживайте движение сотрудников за выбранный период времени.
                  <br />
                  Выберите дату или сотрудника, чтобы посмотреть список треков и перейти в нужный
                  трек.
                </Typography>
              </Grid>
            </>
          )}
          {/* {heatMap && <Image heatMap={heatMap} />} */}
          {track && <Map track={track} />}
        </>
      )}
      <Drawer>
        <DrawerContent>
          <MtsBreadcrumb size="s" className={classes.breadcrumb}>
            {[{ name: 'Отчёты', url: paths.reports.root }, { name: title }].map(
              ({ name, url }, index, array) => (
                <MtsBreadcrumbItem
                  key={index}
                  active={index === array.length - 1}
                  {...(url && { onClick: handleBreadcrumbItemClick(url) })}
                >
                  {name}
                </MtsBreadcrumbItem>
              )
            )}
          </MtsBreadcrumb>

          <Typography className={classes.h1} variant="h1Bold">
            Треки
          </Typography>

          <FormRow className={classes.filter}>
            <FormCol xs={12}>
              {/*<BaseInput name="from" label="Дата от" type="date" value={from} onChange={handleChange} />*/}
              <FormDoubleField
                label="Дата от"
                separator=""
                left={
                  <InputWithDate
                    size={'s'}
                    name="trackFrom"
                    value={trackFrom}
                    maxDate={new Date()}
                    onChange={handleChange}
                  />
                }
                right={
                  <TimePicker name="trackTimeFrom" value={trackTimeFrom} onChange={handleChange} />
                }
              />
              {/*<InputWithDate*/}
              {/*  name="trackFrom"*/}
              {/*  label="Дата от"*/}
              {/*  value={trackFrom}*/}
              {/*  maxDate={new Date()}*/}
              {/*  onChange={handleChange}*/}
              {/*/>*/}
            </FormCol>
            <FormCol xs={12}>
              {/*<BaseInput name="to" label="Дата до" type="date" value={to} onChange={handleChange} />*/}
              <FormDoubleField
                label="Дата до"
                separator=""
                left={
                  <InputWithDate
                    size={'s'}
                    name="trackTo"
                    value={trackTo}
                    maxDate={new Date()}
                    onChange={handleChange}
                  />
                }
                right={
                  <TimePicker name="trackTimeTo" value={trackTimeTo} onChange={handleChange} />
                }
              />
              {/*<InputWithDate*/}
              {/*  name="trackTo"*/}
              {/*  label="Дата до"*/}
              {/*  value={trackTo}*/}
              {/*  maxDate={new Date()}*/}
              {/*  onChange={handleChange}*/}
              {/*/>*/}
            </FormCol>
            <FormCol xs={12}>
              <Dropdown
                name="trackUsers"
                label="Сотрудник (необязательно)"
                placeholder="Выберите значение"
                groupOptions={brigadeOptions}
                multiple
                value={trackUsers}
                onMtsChange={handleChange}
              />
            </FormCol>
            <FormCol xs="auto">
              <MtsButton loading={isLoadingGetTracks} onClick={handleClick}>
                Применить
              </MtsButton>
            </FormCol>
          </FormRow>
          {isLoading && <Spinner />}
          {isLoadingError && <EmptyState variant="serverError" />}
          {!isLoading && !isLoadingError && (
            <>
              {!tracks || (!tracks.tracks.length && <EmptyState variant="emptyRequest" />)}
              {tracks && tracks.tracks.length !== 0 && (
                <>
                  {tracks.tracks.map((item: any) => (
                    <Item key={item.userId} track={item} />
                  ))}
                </>
              )}
            </>
          )}
        </DrawerContent>
      </Drawer>
    </>
    // <Content
    //   breadcrumb={[{ name: 'Отчёты', url: paths.reports.root }, { name: title }]}
    //   title={title}
    // >
    //   {!isLoadingGetData && !isGetDataLoadingError && (
    //     <>
    //       {!isLoading && !isLoadingError && (
    //         <>
    //           {!heatMap && <EmptyState variant="generateReport" />}
    //           {/* {heatMap && <Image heatMap={heatMap} />} */}
    //           {heatMap && <Map heatMap={heatMap} />}
    //         </>
    //       )}
    //     </>
    //   )}
    //   {isLoading && <Spinner />}
    //   {isLoadingError && <EmptyState variant="serverError" />}
    // </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(HeatMap);
