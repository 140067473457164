/**
 * Сервис для работы с разделом администратора
 */

import axios from 'axios';

const url = '/admin';

const AdminService = {
  getCompanyConfig: () => axios.get(`${url}/settings/company`)
};

export default AdminService;
