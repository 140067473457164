import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsFooter, MtsFooterBottom } from '@mts-ds/components-react';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    zIndex: 0,
    padding: spacing(0, 11),
    '--grid-m-breakpoint': 0,
    '--grid-m-margin': 0,
    '--grid-t-breakpoint': 0,
    '--grid-t-margin': 0,
    '--grid-dxs-breakpoint': 0,
    '--grid-dxs-margin': 0,
    '--grid-ds-breakpoint': 0,
    '--grid-ds-max-width': 0,
    '--grid-ds-margin': 0,
    '--grid-dm-breakpoint': 0,
    '--grid-dm-max-width': 0,
    '--grid-dm-margin': 0,
    '--grid-dl-breakpoint': 0,
    '--grid-dl-max-width': 0,
    '--grid-dl-margin': 0,
    '--grid-dxl-breakpoint': 0,
    '--grid-dxl-max-width': 0,
    '--grid-dxl-margin': 0
  }
}));

interface IProps {
  isForbidden?: boolean;
}

const Footer: FC<IProps> = ({ isForbidden }) => {
  const classes = useStyles();

  return (
    <MtsFooter className={classes.root}>
      <MtsFooterBottom>
        <Grid container justify="space-between">
          <Grid item>{new Date().getFullYear()} 18+</Grid>
          {!isForbidden && (
            <Grid item>
              <Grid container alignItems="center">
                {/*<MtsLink href="mailto:vaniki20@mts.ru">Обратная связь</MtsLink>*/}
                {/*<Icon name="arrowDown" color="text-primary-link" />*/}
              </Grid>
            </Grid>
          )}
          <Grid item>Круглосуточная служба поддержки: 8 800 250 11 11. Бесплатно по России</Grid>
        </Grid>
      </MtsFooterBottom>
    </MtsFooter>
  );
};

export default Footer;
