import { ICoord, ICoordXY, ICoordXYXY, IUserStatus, UserStatusPriority } from '../../models/types';

const getPolygonPositions = (positions: ICoord): Array<[number, number]> => {
  // приводим к порядку latlon
  const TempPositions = positions.slice(0);
  for (let i = 0; i < TempPositions.length - 1; i += 2) {
    [TempPositions[i], TempPositions[i + 1]] = [TempPositions[i + 1], TempPositions[i]];
  }
  // оборачиваем в отдельные массивы
  const result = TempPositions.reduce<number[][]>(
    (acc, coord) => {
      const lastEl = acc.length - 1;

      if (acc[lastEl].length < 2) {
        acc[lastEl].push(coord);
      } else {
        acc.push([coord]);
      }

      return acc;
    },
    [[]]
  );
  return result as Array<[number, number]>;
};

const getBoxCenter = ([x, y, z, w]: ICoord): ICoordXY => {
  return [y + w, x + z].map(coord => coord / 2) as ICoordXY;
};

const getBoxBounds = ([x, y, z, w]: ICoord): ICoordXYXY => {
  return [[y, x], [w, z]];
};

const getCircleCenter = ([x, y, z]: ICoord): ICoordXY => {
  return [y, x];
};

const getCircleRadius = ([x, y, z]: ICoord): number => {
  return z;
};

const getUserStatuses = (statuses: IUserStatus[]) => {
  return statuses.filter(({ priority }) => priority !== UserStatusPriority.HIDDEN);
};

export {
  getPolygonPositions,
  getBoxCenter,
  getBoxBounds,
  getCircleCenter,
  getCircleRadius,
  getUserStatuses
};
