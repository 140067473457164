/**
 *  Модель для работы с разделом администратора
 */

import { createModel, ModelConfig } from '@rematch/core';
import AdminService from '../services/AdminService';
import { ICompanyConfig } from './types';

// tslint:disable-next-line:no-empty-interface
interface IState extends ICompanyConfig {}

const initialState: IState = {
  companyName: '',
  dispatcherPhoneNumber: '',
  tenantId: '',
  isProtectionsUsed: false,
  allowRemapPermProtections: false,
  isWebSsoUsed: false,
  isLobbyUsed: false,
  isGoodwinBppuUsed: false,
  isCamUsed: false,
  userActivityTrackingSettings: {
    offlineTimeoutSecondsMin: 0,
    offlineTimeoutSeconds: 0,
    actualPositionTimeoutSecondsMin: 0,
    actualPositionTimeoutSeconds: 0,
    leaveAreaTimeoutSecondsMin: 0,
    leaveAreaTimeoutSeconds: 0,
    immobilizedTimeMinutes: 0,
    immobilizedAfterFallSeconds: 0,
    immobilizedCheckWindowSeconds: 0,
    sendSuccessTimeoutSeconds: 0,
    sendErrorRepeatTimeoutSeconds: 0,
    immobilizedZoneFlags: '',
    immobilizedOrientation: '',
    fallOrientation: '',
    sourceFlags: ''
  },
  workshiftSettings: {
    workshiftDurationHours: 0,
    workshiftBreakMinutes: 0,
    workshiftCooldownMinutes: 0,
    workshiftCheckPeriodMinutes: 0,
    immobilizedWorkshift: ''
  },
  mapKeys: {
    tomtom: '',
    egis: ''
  },
  loaded: ''
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setCompanyConfig(state: IState, payload: ICompanyConfig): IState {
      return { ...state, ...payload };
    }
  },
  effects: {
    async getCompanyConfig() {
      try {
        const { data } = await AdminService.getCompanyConfig();

        this.setCompanyConfig(data);
      } catch (error) {
        return error;
      }
    }
  }
};

export const admin: typeof model = createModel(model);
