import React, { FC } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MtsDivider } from '@mts-ds/components-react';

import { IBeacon } from '../../../../../../models/types';

import Typography from '../../../../../../components_/Typography';

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    '& > *:not(:last-child)': {
      marginRight: spacing(0.5)
    }
  },
  divider: {
    margin: spacing(2, 0)
  }
}));

interface IProps {
  item: IBeacon;
  isGeo: boolean;
}

const Attributes: FC<IProps> = ({ item, isGeo }) => {
  const {
    macAddress,
    name,
    hardwareTypeFriendly,
    instanceId,
    calibratedRssi,
    calibratedDistance,
    lat,
    lon,
    alt,
    x,
    y,
    z,
    queryTime,
    mapName,
    officeName
  } = item;

  const classes = useStyles();

  return (
    <>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography variant="p2Medium">Название:</Typography>
        <Typography variant="p2Regular">{name}</Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography variant="p2Medium">MAC адрес:</Typography>
        <Typography variant="p2Regular">{macAddress}</Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography variant="p2Medium">Тип:</Typography>
        <Typography variant="p2Regular">{hardwareTypeFriendly}</Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography variant="p2Medium">Instance ID:</Typography>
        <Typography variant="p2Regular">{instanceId}</Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography variant="p2Medium">Калибровочная сила:</Typography>
        <Typography variant="p2Regular">{calibratedRssi} dBm</Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography variant="p2Medium">Калибровочная дистанция:</Typography>
        <Typography variant="p2Regular">{calibratedDistance} м</Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography variant="p2Medium">Место/План:</Typography>
        <Typography variant="p2Regular">
          {officeName}, {mapName}
        </Typography>
      </Grid>
      <MtsDivider className={classes.divider} />
      <Grid container className={classes.label}>
        <Typography variant="p2Medium">Координаты:</Typography>
        {isGeo ? (
          <Grid className={classes.divider} container>
            <Grid item xs={12}>
              <Typography variant="p2Regular">{`Широта: ${lat}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p2Regular">{`Долгота: ${lon}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p2Regular">{`Высота: ${
                alt !== undefined && alt !== null ? alt : '-'
              }`}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.divider} container>
            <Grid item xs={12}>
              <Typography variant="p2Regular">{`X: ${x}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p2Regular">{`Y: ${y}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p2Regular">{`Z: ${
                z !== undefined && z !== null ? z : '-'
              }`}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {queryTime && (
        <>
          <MtsDivider className={classes.divider} />
          <Grid container>
            <Typography variant="p3Regular" color="text-secondary">
              Данные получены{' '}
              {moment(queryTime)
                .startOf('hour')
                .fromNow()}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
};

export default Attributes;
